import { useEffect, useRef } from "react";
import { useState } from "react";
import { Page,
    // Logo,
    Area,
    Text,
    ButtonArea,
    ToggleButtonArea,
    BigButtonArea,
    ToggleButton,
    Button,
    Clip,
    Overlay,
    ButtonLink,
    LoadLogo,
    LoadContainer
} from "./styles";

const FullPageLoader = () => {
    return (
        <LoadContainer>
            <LoadLogo />
        </LoadContainer>
    )
}

const Home = () => {
    // const [loading, setLoading] = useState(false);
    // useEffect(() => {
    //     setTimeout(() => { setLoading(false) }, 4000)
    // }, [])

    return (
    <Page>
        <video style={{
            position: "absolute",
            top: "0",
            left: "0",
            display: "block",
            width: "100vw",
            height: "100vh",
            "OobjectFit": "cover",
            "objectFit": "cover",
        }} src="https://gandaya.b-cdn.net/z-summit.mp4" autoPlay loop muted playsInline></video>
        <Overlay />
        <Area>
            <Text />
            <ButtonArea>
                <BigButtonArea>
                    <ButtonLink to="/events/z-summit">Garanta seu Ingresso</ButtonLink>
                    <Button active={true}>
                    <a style={{width: "100%", height: "100%", textDecoration: "none", display: "flex", justifyContent: "center", alignItems: "center"}} href="https://forms.gle/M9gVpz4PePWVBFQm7">
                        <Clip active={true}>
                            <span>Inscrição Jornalistas</span>
                        </Clip>
                    </a>
                    </Button>
                    <Button>
                    <a style={{width: "100%", height: "100%", textDecoration: "none", display: "flex", justifyContent: "center", alignItems: "center"}} href="https://www.zsummit.com.br/info-gerais">
                        <span style={{color: "white"}}>Informações</span>
                    </a>
                    </Button>
                </BigButtonArea>
            </ButtonArea>
        </Area>
    </Page>
    )
}

export default Home;