import { Link } from 'react-router-dom'
import styled from 'styled-components'
import z_invest_png from '../../assets/images/z_invest_logo.png'
import bg_gif from '../../assets/images/z-summit.gif'
import logo_png from '../../assets/images/logo_branco.png'
import logo_png_blck from '../../assets/images/Vertical_Primeiro_Preto.png'


const Page = styled.div`
    height: 100vh;
    width: 100vw;

    // background-image: url(${bg_gif});
    // background-size: cover;
    // background-repeat: no-repeat;
    // background-position: center;

    display: flex;
    align-items: center;
    justify-content: center;

    color: white;
    font-weight: 700;
`

// const Logo = styled.div`
//     position: absolute;
//     top: 10px;
//     left: 10px;

//     background-image: url(${logo_png});
//     background-size: cover;
//     background-repeat: no-repeat;
//     background-position: center;

//     height: 80px;
//     width: 80px;

//     z-index: 2;
// `

const Area = styled.div`
    height: 500px;
    width: 310px;

    // min-height: 350px;
    // min-width: 300px;

    // width: 90%;
    // height: 50%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    z-index: 2;
`

const Text = styled.div`
    background-image: url(${z_invest_png});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    height: 200px;
    width: 240px;
`

const ButtonArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    height: 65%;
    width: 85%;
`

const ToggleButtonArea = styled.div`
    height: 25%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
`

const BigButtonArea = styled.div`
    height: 75%;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`

const ToggleButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 45%;
    height: 60%;
    border: 3px solid white;
    border-radius: 100px;
    ${props => props.active && "background-color: white; color: black;"}

    transition: 0.5s ease all;
    &:active {
        transform: scale(1.02) rotate(1.5deg);
    }

    cursor: pointer;
`

const Button = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 25%;
    border: 3px solid white;
    border-radius: 10px;
    ${props => props.active && "background-color: white; color: black;"}

    transition: 0.1s ease all;
    &:active {
        transform: scale(1.1) rotate(3deg);
    }

    cursor: pointer;
`

const ButtonLink = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 25%;
    border: 3px solid white;
    border-radius: 10px;
    ${props => props.active && "background-color: white; color: black;"}

    transition: 0.1s ease all;
    &:active {
        transform: scale(1.1) rotate(3deg);
    }

    cursor: pointer;

    text-decoration: none; color: white;
`

const Clip = styled.div`
    background: no-repeat center center;
    background-size: 100%;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    ${props => props.active && `background-image: url(${bg_gif});`}
`

const Overlay = styled.div`
    height: 100vh;
    width: 100vw;

    position: fixed;
    top: 0;
    left: 0;

    background-color: rgba(0, 0, 0, 0.3);
    z-index:1;
`

const LoadContainer = styled.div`
    position: fixed;

    top: 0; 
    right: 0;
    height: 100vh;
    width: 100vw;

    background-color: rgba(255, 255, 255, 0.99);

    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 20;
`

const LoadLogo = styled.div`
    background-image: url(${logo_png_blck});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    height: 125px;
    width: 150px;

    z-index: 21;
`

export {
    Page,
    // Logo,
    Area,
    Text,
    ButtonArea,
    ToggleButtonArea,
    BigButtonArea,
    ToggleButton,
    Button, ButtonLink,
    Clip,
    Overlay,
    LoadContainer, LoadLogo
}